import json from '../assets/json/home_faq.json';

const faqToDisplay = 7;
let html = `<h2 class="done-section-faq-title done-section-title-second">ENTREPRISES : DES QUESTIONS <br />SUR LE DON D’INVENDUS AUX ASSOCIATIONS ?</h2>`;
let i = 0;

for (var key in json) {
  html += `<details class="done-section-faq-group done-section-faq-group-light ${
    i >= faqToDisplay ? 'done-section-faq-group-more' : ''
  }">
  <summary class="done-section-faq-group-collapse">
    <h3 class="done-section-faq-group-title">${key}</h3>
    <svg id="arrowDownWithout" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.092 38.828"><g fill="currentColor" data-name="Groupe 204"><path d="M14.046 38.828 7.024 26.664 0 14.501l14.046 3.836L28.092 14.5l-7.024 12.163Z" data-name="Tracé 836"/><path d="M9.813 0h8.467l-1.449 22.519h-5.57Z" data-name="Tracé 837"/></g></svg>
  </summary>
  <p class="done-section-faq-group-paragraph">
  ${json[key]}
</p>
</details>`;
  i++;
}

let faq = document.getElementById('faq');
faq.innerHTML = html;
